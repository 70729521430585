body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --extra-light-purple: rgb(236, 221, 238);
  --extra-light-blue: rgb(222, 228, 240);
  --extra-light-green: rgb(222, 238, 228);
  --extra-light-gray: rgb(240, 240, 240);
  --light-gray: rgb(200, 200, 200);
  --medium-gray: rgb(120, 120, 120);
  --gray: rgb(75, 75, 75);
  --dark-gray: rgb(50, 50, 50);
  color: var(--dark-gray);
}

body h1, h2, h3, h4, p {
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  border-top: solid 1px var(--light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer div {
  font-weight: 400;
}

.section {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 62px);
}

.navbar {
  background-color: white;
  border-bottom: solid 1px var(--light-gray);
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark-gray);
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  text-align: right;
  transition: all .2s;
}

.nav-link.active {
  font-weight: 400;
}

.nav-link:hover {
  color: black !important;
  transform: translate(0%, -5%);
}

.navbar-light .navbar-toggler {
  border: none;
  padding-right: 0px;
}

.navbar-light .navbar-toggler:focus {
  outline: none;
}

.page-content-container {
  width: 100vw;
  height: 100vh;
}

.home {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('http://d3me27l3ng8az4.cloudfront.net/home.jpg');
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@keyframes fade {
  from { opacity: 1; }
  to { opacity: 0.8; }
}

@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.page-content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-content.hidden {
  display: none;
}

.home-content.visible {
  text-align: center;
  margin: 10px;
  width: 600px;
  height: 400px;
  animation: fadein 1s;
  outline: 2px solid white;
  outline-offset: -20px;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;
}

.home-content h1 {
  margin-bottom: 20px;
}

.home-content h3 {
  margin-bottom: 30px;
}

@keyframes bounce {
  0% { transform: translateY(2px) }
  50% { transform: translateY(-1.5px) }
  100% { transform: translateY(2px) }
}

.down-arrow {
  color: var(--medium-gray);
  animation: bounce 2s cubic-bezier(0.34, 0.61, 0.59, 1.11) infinite
}

.down-arrow:hover {
  color: var(--dark-gray);
}

.about {
  margin: 0px 150px 75px 150px;
  padding-top: 100px;
}

.mobile-about {
  margin: 50px;
}

.about-section {
  padding-bottom: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-about-section {
  padding-bottom: 50px;
}

.about-section-text {
  width: 650px;
  max-width: 55vw;
  padding: 35px;
  background-color: var(--extra-light-gray);
  border: solid 1px var(--light-gray);
  opacity: 0.95;
  z-index: 100;
}

.about-section-text h1 {
  font-size: 1.7rem;
}

.about-section-text p {
  margin-bottom: 0px;
}

.mobile-about-section-text {
  max-width: 650px;
}

.mobile-about-section-text p {
  margin-top: 15px;
  margin-bottom: 25px;
}

.about-section-2 {
  margin-left: -90px;
}

.about-photo {
  width: 40vw;
  height: auto;
  border: solid 1px var(--dark-gray);
}

.about-photo.cms {
  border: none;
}

.mobile-about-photo {
  max-width: 75vw;
  height: auto;
}

.portfolio {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--dark-gray);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio h1 {
  color: white;
  margin-bottom: 20px;
  font-weight: 400;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.portfolio-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.portfolio-item {
  position: relative;
  margin: 40px;
  width: 420px;
  height: 240px;
  max-width: 70vw;
  max-height: 40vw;
}

.portfolio-item:hover {
  cursor: pointer;
}

.portfolio-item:hover .screenshots {
  opacity: 0.5;
}

.portfolio-item:hover .external-link-icon {
  display: block;
}

.portfolio-item a {
  height: 100%;
  width: 100%;
}

.header-line {
  width: 175px;
  height: 1px;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 15px;
}

.external-link-icon-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.external-link-icon {
  font-size: 30px;
  color: white;
  display: none;
}

.portfolio-desktop-img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.portfolio-mobile-img {
  width: 50%;
  height: auto;
  position: absolute;
  bottom: -50px;
  right: -50px;
}

.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--extra-light-gray);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonials h1 {
  margin-bottom: 20px;
  font-weight: 400;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.testimonials .header-line {
  background-color: var(--dark-gray);
}

.testimonials-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.testimonial {
  background-color: white;
  width: 400px;
  max-width: 80vw;
  padding: 30px 45px 30px 45px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: -5px 5px 5px 0px var(--light-gray);
}

.testimonial p {
  font-size: 1.2rem;
}

.testimonial .quote {
  text-align: left;
}

.testimonial .client {
  text-align: right;
  font-style: italic;
  font-weight: bold;
}

.testimonials-list {
  margin-top: 10px;
}

.testimonials-list .quote-icon {
  font-size: 40px;
  color: var(--extra-light-gray);
  margin-left: -25px;
  margin-bottom: -20px;
}

.pricing {
  width: 100vw;
  padding: 100px 20px 100px 20px;
}

.pricing-header {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 400;
}

.pricing h2 {
  font-size: 1.7rem;
}

.pricing h3 {
  font-size: 1.5rem;
}

.pricing h4 {
  font-size: 1.3rem;
}

.pricing p {
  font-size: 1.2rem;
  margin-bottom: 0px;
}

.plans {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}

.plan-container {
  width: 450px;
  max-width: 85vw;
  height: auto;
}

.plan {
  padding: 30px;
  margin: 30px;
  border: solid 1px var(--light-gray);
  border-radius: 10px;
  box-shadow: -5px 5px 5px 0px var(--light-gray);
}

.plan-name {
  font-weight: 400;
}

.plan-icon svg {
  font-size: 40px;
  margin-bottom: 10px;
}

.plan .plan-icon, h2, h3, h4 {
  text-align: center;
}

.plan-description {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 1.5;
}

.pricing li {
  margin-bottom: 5px;
}

.plan-details {
  margin-left: 15px;
  margin-right: 15px;
}

.maintenance-container {
  display: flex;
  justify-content: center;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.maintenance h3 {
  margin-bottom: 15px;
}

.maintenance ul {
  max-width: 625px;
}

.contact {
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 120px;
  padding-bottom: 75px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contact-form {
  width: 400px;
  margin-right: 100px;
  background-color: var(--extra-light-gray);
  padding: 40px;
  border-radius: 10px;
  border: solid 1px var(--medium-gray);
}

.consultation-button {
  display: flex;
  justify-content: center;
}

.send-email-text {
  text-align: center;
  font-size: 20px;
}

.send-email-text-2 {
  text-align: center;
}

.line-header {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--gray);
}

.line-header:before, .line-header:after {
  content: '';
  border-top: 1px solid var(--light-gray);
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}

.line-header:after {
  margin: 0 0 0 20px;
}

.modal, .modal-title {
  text-align: center;
}

.modal-title {
  width: 100%;
  padding-top: 15px;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}

.modal-content {
  border-radius: 10px;
}

@media all and (max-width: 992px) {
  .navbar-light .navbar-nav .nav-link {
    padding-right: 0px;
  }
}

@media all and (min-width: 601px) {
  .contact {
    background: url('http://d3me27l3ng8az4.cloudfront.net/contact.jpg');
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
}

@media all and (max-width: 600px) {
  .home-content {
    height: 300px;
    max-width: 90vw;
  }

  .home-content h1 {
    font-size: 32px;
  }

  .home-content h3 {
    font-size: 22px;
  }

  .mobile-about h1 {
    font-size: 24px;
  }

  .mobile-about p {
    font-size: 15px;
  }

  .pricing {
    padding: 75px 50px 60px 50px;
  }

  .portfolio h1, .testimonials h1, .pricing h1 {
    font-size: 24px;
  }

  .header-line {
    width: 100px;
  }

  .testimonials-list .quote-icon {
    font-size: 30px;
  }

  .testimonial {
    padding: 25px 40px 25px 40px;
  }

  .testimonial p {
    font-size: 13px;
  }

  .pricing h2 {
    font-size: 20px;
  }

  .pricing h3 {
    font-size: 20px;
  }

  .pricing h4 {
    font-size: 16px;
  }

  .pricing p {
    font-size: 13px;
  }

  .plan {
    padding: 20px;
    margin: 15px;
  }

  .plan-description {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pricing li {
    margin-bottom: 2px;
  }

  .contact {
    padding: 35px 0px 35px 0px;
    height: 100%;
    min-height: calc(100vh - 62px);
    background-color: var(--extra-light-gray);
    justify-content: center;
  }

  .contact img {
    display: none;
  }

  .contact-form {
    margin: 0px;
    border-radius: 0px;
    border: none;
    width: 100%;
    height: 100%;
  }
}
